import * as React from "react"
import { SVGProps } from "react"

const SoftwareIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="100"
		height="100"
		viewBox="0 0 100 100"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_8912_12647)">
			<rect
				x="10"
				y="19.9619"
				width="80"
				height="60.0762"
				rx="4"
				stroke="#3021E5"
				strokeWidth="3"
			/>
			<circle cx="17.2331" cy="25.7507" r="1.91667" fill="#3021E5" />
			<circle cx="22.9001" cy="25.7507" r="1.91667" fill="#3021E5" />
			<circle cx="28.5671" cy="25.7507" r="1.91667" fill="#3021E5" />
			<path
				d="M10.2139 31.501H84.2129"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M66.001 47.6802H83.9991"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M66.001 39.3853H83.9991"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M66.001 55.9365H75"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M59.9999 31.5015L60 79.9169"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M10.2139 47.6802L60.0003 47.6802"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M10.2139 64.2314L60.0003 64.2314"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M26.6504 32.21L26.6504 78.917"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M43.333 32.21L43.333 78.917"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<rect
				x="66.2144"
				y="64.2314"
				width="17.9985"
				height="8"
				rx="1"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_8912_12647">
				<rect width="100" height="100" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export default SoftwareIcon
