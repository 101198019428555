import * as React from "react"
import { SVGProps } from "react"

const EquipmentIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="100"
		height="100"
		viewBox="0 0 100 100"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_8912_12698)">
			<path
				d="M29.9991 39.972H23.9983C21.7892 39.972 19.9983 41.7629 19.9983 43.9721V86.1709C19.9983 88.3801 21.7891 90.1709 23.9983 90.1709H76.0017C78.2108 90.1709 80.0017 88.3801 80.0017 86.1709V43.972C80.0017 41.7629 78.2108 39.972 76.0017 39.972H70.0008"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M30 9.80115L35 14.8011L40 9.80115L45 14.8011L50 9.80115L55 14.8011L60 9.80115L65 14.8011L70 9.80115V60H30V9.80115Z"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinejoin="round"
			/>
			<path
				d="M26 60H74"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M20 70H80"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M37.5 40H62.5"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M37.5 48.3873H50M62.5 48.3873H58.25"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M37.5 32H62.5"
				stroke="#3021E5"
				strokeWidth="3"
				strokeLinecap="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_8912_12698">
				<rect width="100" height="100" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export default EquipmentIcon
