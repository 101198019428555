import React, { useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import PhoneBackdropBubble from "./assets/PhoneBackdropBubble"
import { getData }   from "./data"
import ConsultationCtaFormA from "../../../components/_V2/ConsultationCtaFormA"
import FryCall from "./assets/FryCall"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

export enum CTA_FROM_THEMES {
	DEFAULT = "DEFAULT",
	CYAN = "CYAN",
	FUCHSIA = "FUCHSIA",
}

interface Props {
	className?: string
	isWhiteBackground?: boolean
	theme?: CTA_FROM_THEMES
}

export default function CtaForm(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	const isWhiteBackground = props.isWhiteBackground || false

	const theme = props.theme || CTA_FROM_THEMES.DEFAULT
	const isCyanTheme = theme === CTA_FROM_THEMES.CYAN || false
	const isFuchsiaTheme = theme === CTA_FROM_THEMES.FUCHSIA || false

	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	const type = "b"

	return (
		<>
			<section
				className={classNames(
					styles.section,
					props.className,
					{ [styles.sectionThemeCyan]: isCyanTheme },
					{ [styles.sectionThemeFuchsia]: isFuchsiaTheme }
				)}
			>
				<div
					className={classNames(styles.content, {
						[styles.contentWhite]: isWhiteBackground,
					})}
				>
					<div className={styles.titleWrapper}>
						<p className={styles.subTitle}>{data.subTitle}</p>
						<div className={styles.illustration}>
							{<FryCall className={styles.illustrationImage} />}

							<span className={styles.title}>{data.title}</span>
						</div>
					</div>
					<div className={styles.buttonsWrapper}>
						<a
							className={styles.contactPhoneNumberButton}
							href={`tel:${data.phone.value}`}
						>
							<PhoneBackdropBubble
								className={styles.phoneBackdrop}
								fill={
									isCyanTheme
										? "#EFFAFD"
										: isFuchsiaTheme
										? "#FAF5FF"
										: "#F7F5FF"
								}
							/>
							<span className={styles.phoneNumberButtonText}>
								{data.phone.text}
							</span>
						</a>
						<a
							className={styles.ctaButton}
							onClick={handleModalStatus}
							href={"#popup"}
						>
							{data.ctaButtonText}
						</a>
					</div>
				</div>
			</section>

			<ConsultationCtaFormA isOpen={isModalOpen} onClose={handleModalStatus} />
		</>
	)
}
