import * as React from "react"
import { SVGProps } from "react"

const OfficeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="64"
		height="64"
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8 56H56"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M56 56H8"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.208 38.2676V36.7676C12.3796 36.7676 11.708 37.4392 11.708 38.2676H13.208ZM11.708 56C11.708 56.8284 12.3796 57.5 13.208 57.5C14.0364 57.5 14.708 56.8284 14.708 56H11.708ZM13.208 39.7676H26.7926V36.7676H13.208V39.7676ZM14.708 56V38.2676H11.708V56H14.708Z"
			fill="#2A2D33"
		/>
		<path
			d="M26.793 20.5347H50.7923V55.9999H26.793V20.5347Z"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinejoin="round"
		/>
		<path
			d="M34.0425 46.2673H43.5425"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M34.0425 38.2673H43.5425"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M34.0425 30.2673H43.5425"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M38.7925 15.7466V7.25391L20 15.2539V38.2113"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default OfficeIcon
