import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import  { getData }  from "./data"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function Contacts(props: Props) {
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)

	return (
		<>
			<section
				itemScope
				itemType="https://schema.org/Organization"
				className={classNames(styles.contacts, props.className)}
			>
				{data.map((contactsItem, i) => (
					<div
						className={classNames(styles.contactsItem, styles.contacts__item)}
						key={i}
					>
						<div className={styles.contactsItem__icon}>{contactsItem.icon}</div>

						{contactsItem.content.map((item, j) => (
							<div key={j} className={styles.contactsItem__contentItem}>
								<span className={styles.contactsItem__label}>{item.label}</span>
								<span className={styles.contactsItem__value}>{item.value}</span>
								{ item.hint && <span className={styles.contactsItem__hint}>{item.hint}</span> }
							</div>
						))}
					</div>
				))}
			</section>
		</>
	)
}
