import {Locales} from "../../../localization/types";

export const getData = (locale :Locales) => {
	if (locale === "kz-KZ") {
		return {
			title: "Telegram-дағы байланыс деректеріміз",
			chat: {
				title: "Бизнес, ақша, қоғамдық тамақтандыру",
				about: "Рестораторлар мен сарапшыларға арналған чат",
				link: "https://t.me/resto_business",
				bubble: "./assets/chat-bubble.png",
			},
			bot: {
				title: "Quick Resto Бот",
				about: "Техникалық қолдау",
				link: "https://t.me/QuickResto_bot",
				bubble: "./assets/bot-bubble.png",
			},
			community: {
				title: "Quick Resto Community",
				about: "Пайдаланушылар қоғамдастығы",
				link: "https://t.me/QuickRestoCommunityBot",
				bubble: "./assets/community-bubble.png",
			},
		}
	}

	return {
		title: "Наши контакты в Telegram",
		chat: {
			title: "Бизнес, деньги, общепит",
			about: "Чат для рестораторов и экспертов",
			link: "https://t.me/resto_business",
			bubble: "./assets/chat-bubble.png",
		},
		bot: {
			title: "Quick Resto Бот",
			about: "Техподдержка",
			link: "https://t.me/QuickResto_bot",
			bubble: "./assets/bot-bubble.png",
		},
		community: {
			title: "Quick Resto Community",
			about: "Сообщество пользователей",
			link: "https://t.me/QuickRestoCommunityBot",
			bubble: "./assets/community-bubble.png",
		},
	}
}
export default {
	title: "Наши контакты в Telegram",
	chat: {
		title: "Бизнес, деньги, общепит",
		about: "Чат для рестораторов и экспертов",
		link: "https://t.me/resto_business",
		bubble: "./assets/chat-bubble.png",
	},
	bot: {
		title: "Quick Resto Бот",
		about: "Техподдержка",
		link: "https://t.me/QuickResto_bot",
		bubble: "./assets/bot-bubble.png",
	},
	community: {
		title: "Quick Resto Community",
		about: "Сообщество пользователей",
		link: "https://t.me/QuickRestoCommunityBot",
		bubble: "./assets/community-bubble.png",
	},
}
