import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'
import Presentation from '../pagesSections/index/ProductPresentation/assets/pres.png'
import PresentationBy from '../pagesSections/index/ProductPresentation/assets/pres_by.png'
import PresentationKz from '../pagesSections/index/ProductPresentation/assets/pres_kz.png'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/contacts.module.scss'
import Contacts from '../pagesSections/contacts/Contacts'
import Requisites from '../pagesSections/contacts/Requisites'
import TgChats from '../pagesSections/contacts/TgChats'
import CtaForm from '../pagesSections/contacts/CtaForm'
import ContactsMap from '../components/_V2/ContactsMap'
import { useLocalizationContext } from '../localization/useLocalizationContext'
import { externalLinks } from '../pages-data/_V2/common/links'

export default function PageContacts() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Контакты для связи | Quick Resto',
    'ru-KZ': 'Контакты для связи | Quick Resto Казахстан',
    'kz-KZ': 'Контакты для связи | Quick Resto Казахстан',
    'ru-BY': 'Контакты для связи | Quick Resto Беларусь',
  }
  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}contacts/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}contacts/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}contacts/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}contacts/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Контактные данные компании Quick Resto: телефон круглосуточной горячей линии, электронная почта, адрес офиса в Москве и график работы, реквизиты для покупки программного обеспечения и для покупки кассового оборудования',
    'ru-KZ': 'Контактные данные компании Quick Resto в Казахстане: телефон круглосуточной горячей линии, электронная почта, адрес офиса в Алматы и график работы, реквизиты для покупки программного обеспечения и для покупки кассового оборудования',
    'kz-KZ': 'Контактные данные компании Quick Resto в Казахстане: телефон круглосуточной горячей линии, электронная почта, адрес офиса в Алматы и график работы, реквизиты для покупки программного обеспечения и для покупки кассового оборудования',
    'ru-BY': 'Контактные данные компании Quick Resto в Беларуси: телефон круглосуточной горячей линии, электронная почта, адрес офиса в Минске и график работы, реквизиты для покупки программного обеспечения и для покупки кассового оборудования',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Presentation}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresentationBy}`,
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:url',
      content: metaUrl[localizationContext.locale],
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale]
    },
    {
      httpEquiv: 'x-ua-compatible',
      content: 'ie=edge',
    },
  ]

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
      )}
      linkCanonical={currentCanonicalLink}
      footerClassName={pageStyles.pageSection}
    >
      <div className={styles.contacts__backdrop} />

      <h1 className={styles.contacts__title}>Контакты Quick Resto</h1>

      <Contacts className={pageStyles.pageSection} />

      <ContactsMap className={pageStyles.pageSection} />

      <Requisites className={pageStyles.pageSection} />

      <TgChats className={pageStyles.pageSection} />

      <CtaForm isWhiteBackground className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}
