import * as React from "react"
import { SVGProps } from "react"

const Icon247 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="64"
		height="64"
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M45.2541 37.3763L50.0004 26.6235H44.1812"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M34.9946 37.5165L39.7414 26.4834"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M14.5348 29.2417C14.5348 27.7183 15.7697 26.4834 17.293 26.4834C18.8164 26.4834 20.0513 27.7183 20.0513 29.2417C20.0513 29.8516 19.8533 30.4153 19.5181 30.872L13.9995 37.5165H19.7578"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M29.2032 37.5165V26.4834L22.7915 34.7748H31.0005"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M32 8C18.7452 8 8 18.7452 8 32C8 45.2548 18.7452 56 32 56C45.2548 56 56 45.2548 56 32C56 25.4917 53.4094 19.5886 49.2032 15.2653"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M31.4888 12.0054L35.4939 8.00019"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M31.4888 3.99509L35.4939 8.00027"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
	</svg>
)

export default Icon247
