import React from "react"

import styles from "./styles.module.scss"

import SoftwareIcon from "../../../assets/_V2/svg/SoftwareIcon"
import EquipmentIcon from "../../../assets/_V2/svg/EquipmentIcon"

import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			title: "Реквизиты",
			items: [
				{
					id: 'Requisites-by-1',
					icon: <SoftwareIcon className={styles.requisitesItem__icon} />,
					title: "Продажа программного обеспечения",
					description:
						"УНП 692192486Р/С: BY27ALFA30132B01360010270000BIC: ALFABY2X в ЗАО «Альфа-Банк». г. Минск, ул. Сурганова, 43-47",
				},
				{
					id: 'Requisites-by-2',
					icon: <EquipmentIcon className={styles.requisitesItem__icon} />,
					title: (
						<span className={styles.equipmentsTitle}>Продажа оборудования</span>
					),
					description:
						"УНП 692192486Р/С: BY27ALFA30132B01360010270000BIC: ALFABY2X в ЗАО «Альфа-Банк». г. Минск, ул. Сурганова, 43-47",
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Деректемелер",
			items: [
				{
					id: 'Requisites-kz-1',
					icon: <SoftwareIcon className={styles.requisitesItem__icon} />,
					title: "Бағдарламалық жасақтаманы сату",
					description:
						"Астана қ., \n" +
						"Республика даңғылы, 34-үй\n" +
						"\"Ресторанная\n" +
						"автоматизация\" ЖШС \n" +
						"Шот нөмірі: KZ6782117AXH10000001\n" +
						"БСК: KINCKZKA\n" +
						"БСН/ЖСН: 221040012348",
				},
				{
					id: 'Requisites-kz-2',
					icon: <EquipmentIcon className={styles.requisitesItem__icon} />,
					title: (
						<span className={styles.equipmentsTitle}>Жабдықты сату</span>
					),
					description:
						"Астана қ., \n" +
						"Республика даңғылы, 34-үй\n" +
						"\"Ресторанная\n" +
						"автоматизация\" ЖШС\n" +
						"Шот нөмірі: KZ6782117AXH10000001\n" +
						"БСК: KINCKZKA\n" +
						"БСН/ЖСН: 221040012348",
				},
			],
		}
	}

	if (locale === 'ru-KZ') {
		return {
			title: "Реквизиты",
			items: [
				{
					id: 'Requisites-kz-1',
					icon: <SoftwareIcon className={styles.requisitesItem__icon} />,
					title: "Продажа программного обеспечения",
					description:
						"г.Астана, \nПроспект Республика, д.34\nТОО \"Ресторанная\nавтоматизация\" \nНомер счета: KZ6782117AXH10000001\nБИК: KINCKZKA\nБИН/ИИН: 221040012348",
				},
				{
					id: 'Requisites-kz-2',
					icon: <EquipmentIcon className={styles.requisitesItem__icon} />,
					title: (
						<span className={styles.equipmentsTitle}>Продажа оборудования</span>
					),
					description:
						"г.Астана, \nПроспект Республика, д.34\nТОО \"Ресторанная\nавтоматизация\"\nНомер счета: KZ6782117AXH10000001\nБИК: KINCKZKA\nБИН/ИИН: 221040012348",
				},
			],
		}
	}

	return {
		title: "Реквизиты",
		items: [
			{
				id: 'Requisites-1',
				icon: <SoftwareIcon className={styles.requisitesItem__icon} />,
				title: "Продажа программного обеспечения",
				description:
					"ООО «Квик Ресто» \nИНН/КПП 7726734798 / 771401001\nОГРН 5137746099046\nЮридический адрес: 125167,\nг. Москва, Ленинградский пр-кт. д. 36 стр. 30, помещ. 96.\nР/с 40702810410001557795\nК/с 30101810145250000974\nАО «Тинькофф Банк»\nБИК 044525974",
			},
			{
				id: 'Requisites-2',
				icon: <EquipmentIcon className={styles.requisitesItem__icon} />,
				title: (
					<span className={styles.equipmentsTitle}>Продажа оборудования</span>
				),
				description:
					"ООО «Квик Ресто Маркет»\nИНН/КПП 7730173993 / 771401001\nОГРН 1157746506081\nЮридический адрес: 125167,\nг. Москва, Ленинградский пр-кт. д. 36 стр. 30, помещ. 96.\nР/с 40702810010001487841\nК/с 30101810145250000974\nАО «Тинькофф Банк»\nБИК 044525974",
			},
		],
	}
}
