import React from "react"

import {contacts, contactsBy, contactsKz} from "../../../pages-data/_V2/common/contacts"
import {Locales} from "../../../localization/types";

const data = {
	title: "Пора открывать!",
	subTitle: "мы можем и перезвонить",
	phone: contacts.phone,
	ctaButtonText: "Заказать звонок",
}

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			...data,
			phone: contactsKz.phone,
			title: 'Я всё понял, погнали!',
			subTitle: "позвонить и оформить заявку",
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Ашу уақыты келді!",
			subTitle: "біз қайта қоңырау шала аламыз",
			phone: contactsKz.phone,
			ctaButtonText: "Қоңырауға тапсырыс беру",
		}
	}

	if (locale === 'ru-BY') {
		return {
			...data,
			phone: contactsBy.phone
		}
	}

	return {
		...data,
	}
}
