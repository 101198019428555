import React, { useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import { getData } from "./data"
import ChevronRight from "../../../assets/_V2/svg/ChevronRight"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function TgChats(props: Props) {
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)

	return (
		<>
			<section className={classNames(styles.tgChats, props.className)}>
				<h2 className={styles.tgChats__title}>{data.title}</h2>

				<div className={styles.tgChats__chats}>
					<div className={styles.tgChats__topRow}>
						<a
							className={classNames(styles.chat, styles.chatChat)}
							href={data.chat.link}
							rel="nofollow"
							target="_blank"
						>
							<div className={styles.chat__img} />
							<div className={styles.chat__content}>
								<span className={styles.chat__title}>
									{data.chat.title}

									<ChevronRight />
								</span>

								<span className={styles.chat__about}>{data.chat.about}</span>
							</div>
						</a>
					</div>
					<div className={styles.tgChats__bottomRow}>
						<a
							className={classNames(styles.chat, styles.chatBot)}
							href={data.bot.link}
							rel="nofollow"
							target="_blank"
						>
							<div className={styles.chat__img} />
							<div className={styles.chat__content}>
								<span className={styles.chat__title}>
									{data.bot.title}

									<ChevronRight />
								</span>

								<span className={styles.chat__about}>{data.bot.about}</span>
							</div>
						</a>
						<a
							className={classNames(styles.chat, styles.chatCommunity)}
							href={data.community.link}
							rel="nofollow"
							target="_blank"
						>
							<div className={styles.chat__img} />
							<div className={styles.chat__content}>
								<span className={styles.chat__title}>
									{data.community.title}

									<ChevronRight />
								</span>

								<span className={styles.chat__about}>
									{data.community.about}
								</span>
							</div>
						</a>
					</div>
				</div>
			</section>
		</>
	)
}
